import React, { useState, useEffect } from 'react';
import logo from '../../centered-no-text.png';
import NavModule from '../Modules/NavModule';
import '../sectionsCss/Nav.css';
import { getDomainUrl } from '../../utils/getDomainUrl'; 


const Nav = ({ to }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const toggleMobMenu = () => {
        setIsOpen((open) => !open);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        const handleSwipe = (e) => {
            const touch = e.changedTouches[0];
            if (touch.clientX > window.innerWidth - 100) {
                closeMenu();
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchend', handleSwipe);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchend', handleSwipe);
        };
    }, []);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const mainDomain = getDomainUrl();

    return (
        <header>
            <div>
                <nav className={`nav ${isScrolled ? 'scrolled' : ''} ${isLoaded ? 'loaded' : ''}`}>
                    <div className='desktopNavContainer'>
                        <div className={`Logo-Nav ${isScrolled ? 'scrolled' : ''}`}>
                            <a href={`${mainDomain}#`}>
                                <img src={logo} className="App-logo" alt="logo" />
                            </a>
                        </div>
                        <div className='Menu'>
                            <a href={`${mainDomain}#TheProject`}>About</a>
                            <a href={`${mainDomain}#playStyles`}>Playstyles</a>
                            <a href={`${mainDomain}#FAQs`}>FAQ</a>
                            <a href={`${mainDomain}#Contact`}>Contact</a>
                            <a href={`${mainDomain}#Partners`}>Partners</a>
                        </div>
                    </div>
                    <div className='contactDiv'>

                        {/*
                        <a href={`/${to}`}>
                            <button className='ContactBtn'>
                                Join the Beta
                            </button>
                        </a>
                        */}

                        <a href="https://store.steampowered.com/app/2938200/Strike_Protocol/" target="_blank" rel="noferrer noreferrer">
                            <button className='cta-btn-nav'>
                                <img className="cta-hero-nav" src={require('../../assets/herosection/steam.png')} alt={''} />
                            </button>
                        </a>

                        <a href="https://store.epicgames.com/en-US/p/strike-protocol-c4d50b" target="_blank" rel="noferrer noreferrer">
                            <button className='cta-btn-nav'>
                                <img className="cta-hero-nav" src={require('../../assets/herosection/epic.png')} alt={''} />
                            </button>
                        </a>

                    </div>
                    <div className={`HamburgerMenu ${isScrolled ? 'scrolled' : ''}`}>
                        <img onClick={toggleMobMenu} src={require('../../assets/herosection/amburgerMeni.png')} alt="Menu" />
                    </div>
                    <NavModule isOpen={isOpen} closeMenu={closeMenu} />
                </nav>
            </div>
        </header>
    );
};

export default Nav;
