import React from 'react';
import '../sectionsCss/Hero.css';

const Hero = ({to}) => {
  return (
    <section className='Hero'>
      <div>
          <img src={require('../../assets/herosection/no-bg1-3.png')} className='title' alt={'Strike Protocol'} />    
          </div>
          <h1 className="Main_title">Strike Protocol</h1>
          <div className='HeroButtonsDiv'>
              {/* --Remove watch trailer btn temporary
              <a href="https://www.youtube.com/watch?v=AMk0BSqDDIk&ab_channel=StrikeProtocol" target="_blank" rel="noopener noreferrer">
                  <button className='btn1'>
                      <img src={require('../../assets/herosection/carbon_play-filled.png')} alt={''} />
                      <p>
                          Watch Trailer
                      </p>
                      
                  </button> 
              </a>
              */}

              {/*
              <a href={`/${to}`}>
                  <button className='btn2'>
                      
                      <p>
                          Join the beta
                      </p>
                  </button>
              </a>

              <a href="https://store.steampowered.com/app/2938200/Strike_Protocol/" target="_blank" rel="noferrer noreferrer">
                  <button className='btn2'>
                      <img className="cta-hero" src={require('../../assets/herosection/steam.png')} alt={''} />
                  </button>
              </a>

              */}

              <a href="https://store.steampowered.com/app/2938200/Strike_Protocol/" target="_blank" rel="noferrer noreferrer">
                  <button className='cta-btn'>
                      <img className="cta-hero" src={require('../../assets/herosection/steam.png')} alt={''} /> 
                      <p>Get via Steam</p>
                  </button>
              </a>

              <a href="https://store.epicgames.com/en-US/p/strike-protocol-c4d50b" target="_blank" rel="noferrer noreferrer">
                  <button className='cta-btn'>
                      <img className="cta-hero" src={require('../../assets/herosection/epic.png')} alt={''} />
                      <p>Get via Epic Games</p>
                  </button>
              </a>

      </div>  
      </section>

  )
}

export default Hero