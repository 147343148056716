import AlphaRegister from "./components/AlphaRegister";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import CookiePolicy from "./components/CookiePolicy";
import InvestorPage from "./components/InvestorPage";
import NotFound from "./components/NotFound";
import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/alpha-registration',
        element: <AlphaRegister />
        },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
    },
    {
        path: '/terms-and-conditions',
        element: <TermsConditions />
    }, 
    {
        path: '/cookie-policy',
        element: <CookiePolicy />
    },
    {
        path: '/investor-info',
        element: <InvestorPage />
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export default AppRoutes;
