import React, { useState, useEffect } from "react";
import "../sectionsCss/FAQ.css";

const QA = [
  {
    q: "What is Strike Protocol?",
    a: "Strike Protocol is a modern warfare dungeon crawler/dungeon builder game. Players can build their bases, set traps, and infiltrate others bases to earn rewards.",
  },
  {
    q: "How can I earn rewards in the game?",
    a: "Rewards can be earned through successful infiltrations and defenses. Infiltrators earn weapons and gear by completing missions, while Commanders earn weapons and gear by successfully defending their bases.",
  },
  {
    q: "How can I earn Credits?",
    a: "Credits, the hard currency in Strike Protocol, can be earned through Daily Quests, Weekly Quests, and special events. These Credits can be used to purchase packs, gear, and weapons.",
  },
  {
    q: "Is this game linked to NFTs or cryptocurrency?",
    a: "While the development of Strike Protocol is financed through cryptocurrency, the game itself does not involve NFTs. Players earn weapons, gear, and Credits through gameplay.",
  },
  {
    q: "What types of gear and weapons are available?",
    a: "The game features a variety of weapons and gear, including martial weapons (like bats and katanas) and firearms (like shotguns and rifles). Players can also customize their characters with different outfits and equipment.",
  },
  {
    q: "Can I merge or upgrade my gear and weapons?",
    a: "Yes, players can combine or merge gear and weapons to create more powerful items, enhancing their stats and abilities.",
  },
  {
    q: "What are the main gameplay modes?",
    a: "Strike Protocol features both dungeon crawling and dungeon building modes. Players can infiltrate others bases or defend their own by setting up traps and defenses.",
  },
  {
    q: "How do I get started?",
    a: "Simply download the game, complete the tutorial run, and start exploring the various missions and battles available.",
  },
  {
    q: "Can I play with friends?",
    a: "No, at this stage Strike Protocol does not offer a co-op mode.",
  },
  {
    q: "What platforms is Strike Protocol available on?",
    a: "Strike Protocol is currently available on PC, with potential plans for expansion to other platforms in the future",
  },
];

const FAQ = () => {
    const [visibleIndex, setVisibleIndex] = useState(null);
    const [visibleCount, setVisibleCount] = useState(3);
  
    const handleQuestionClick = (index) => {
      if (visibleIndex === index) {
        setVisibleIndex(null);
      } else {
        setVisibleIndex(index);
      }
    };
    const deviceWidth = window.innerWidth;

    useEffect(()=>{
        if(deviceWidth>768){
            setVisibleCount(QA.length)  
        }
    },[deviceWidth])
    const toggleVisibility = () => {
      if (deviceWidth <= 768) {
        if (visibleCount >= QA.length) {
          setVisibleCount(3); // Collapse back to showing only the first 3 FAQs
          scrollToTop(); // Scroll to the top of the FAQ section
        } else {

          setVisibleCount(QA.length); // Show all FAQs
        }
      }
    };
  
    const scrollToTop = () => {
        const element = document.getElementById("FAQs");
        var bodyRect = document.body.getBoundingClientRect(),
            elemRect = element.getBoundingClientRect(),
            offset   = elemRect.top - bodyRect.top;
            if(element){
                window.scrollTo(0,offset-200);
            }
    };
  
    return (
      <section className="FAQ" id="FAQs">
        <h2 className="h2Different">FAQ</h2>
  
        <div>
          {QA.slice(0, visibleCount).map((oneQA, index) => (
            <div key={index} className="constructordivFAQ">
              <div
                className={`FAQQuestion ${visibleIndex === index? "active" : ""}`}
                onClick={() => handleQuestionClick(index)}
              >
                <p>{oneQA.q}</p>
              </div>
              {visibleIndex === index && (
                <div className="FAQAnswer">
                  <p>{oneQA.a}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <button onClick={toggleVisibility} className="toggleButtonFAQ">
          {visibleCount >= QA.length? "Hide" : "Load More"}
        </button>
      </section>
    );
  };

export default FAQ;
