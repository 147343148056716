import React, { useState } from 'react';
import '../sectionsCss/Playstyles.css';

const playstylesData = [
    {
        title: "Infiltrator",
        imgSrc: require('../../assets/Playstyles/InfiltratorStrikeProtocol.png'),
        bgImage: require('../../assets/Playstyles/PlaystylesBG.png'),
        // logoBg: require('../../assets/Playstyles/LogoBehindPlayer.png'), -- temp remove 
        outfitsAndWeapons_Title: "Infiltrator skills",
        skills_Title: "Outfits and weapons",
        outfitsAndWeapons: "Equip your Infiltrators with cutting-edge gear in Strike Protocol. Discover different outfits and weapons, each offering different bonuses and stats. Customize your equipment to match your strategic approach and dominate the base raids.",
        skills: "As an Infiltrator, you embark on missions for epic rewards. Choose a base within your level range and navigate through cunning challenges. Dodge traps, defeat enemies and breach the enemy Commander's database. Successful raids reward you with gear, resources, and currencies."
    },
    {
        title: "Commander",
        imgSrc: require('../../assets/Playstyles/CommanderStrikeProtocol.png'), 
        bgImage: require('../../assets/Playstyles/PlaystylesBG2.png'),
        // logoBg: require('../../assets/Playstyles/LogoBehindPlayer2.png'), -- temp remove
        outfitsAndWeapons_Title: "Traps and Bots",
        skills_Title: "Commanding Skills",
        outfitsAndWeapons: "Commanders rely on hi-tech traps and deadly bots to safeguard their bases. While Infiltrators gather their gear, Commanders' arsenal includes explosive and electrifying traps and armed bots.",
        skills: "As a Commander, you're the architect of challenges. Design your Base with intricate layouts, strategically place traps, and spawn defending units to stop Infiltrators. Your goal is to test their strength and make them fight for every inch. Each Commander creates unique Bases, offering endless challenges for Infiltrators."
    }
];

const Playstyles = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [colorChanged, setColorChanged] = useState(false);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % playstylesData.length);
        setColorChanged(!colorChanged);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + playstylesData.length) % playstylesData.length);
        setColorChanged(!colorChanged);
    };

    const currentPlaystyle = playstylesData[currentIndex];

    return (
        <section className='playStyles' style={{ backgroundImage: `url(${currentPlaystyle.bgImage})` }} id='playStyles'>
            <h2 className={colorChanged ? 'clicked' : ''}>Playstyles</h2>
            <h4 className={colorChanged ? 'clicked' : ''}>{currentPlaystyle.title}</h4>
            <div className='contentWrapper'>
                <div className='PSArrows'>
                    <button onClick={handlePrev} className='arrowLeftPlayStyles'>
                        <img src={require('../../assets/Rewards/ic_baseline-navigate-before.png')} alt='' />
                    </button>
                    <button onClick={handleNext} className='arrowRightPlayStyles'>
                        <img src={require('../../assets/Rewards/ic_baseline-navigate-next.png')} alt='' />
                    </button>
                </div>

                <div className='mainPlayStyles'>
                    <article className='PSskills PSoutfits' id={currentPlaystyle.title}>
                        <h5>{currentPlaystyle.outfitsAndWeapons_Title}</h5>
                        <p>{currentPlaystyle.outfitsAndWeapons}</p>
                    </article>
                    <div className='playerImgFrame'>
                        <div className='playerImgBG' style={{ backgroundImage: `url(${currentPlaystyle.logoBg})` }}>
                            <img src={currentPlaystyle.imgSrc} className='playerImg' id={currentPlaystyle.title} alt={currentPlaystyle.title} />
                        </div>
                    </div>
                    <article className='PSskills PSskillsDescription' id={currentPlaystyle.title}>
                        <h5>{currentPlaystyle.skills_Title}</h5>
                        <p>{currentPlaystyle.skills}</p>
                    </article>
                </div>
            </div>
            <div>             
                <div className="playStylesBreadCrumb">
                    <button
                        className={currentIndex === 0 ? 'breadcrumb active' : 'breadcrumb'}
                        onClick={() => setCurrentIndex(0)}>
                    </button>
                    <button
                        className={currentIndex === 1 ? 'breadcrumb active' : 'breadcrumb'}
                        onClick={() => setCurrentIndex(1)}>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Playstyles;
