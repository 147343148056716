import React from 'react';
import '../sectionsCss/Partners.css';

const partnersData = [
    { name: "Electrocoin", link: "https://electrocoin.eu/" },
    { name: "GD", link: "https://gd-entertainment.hr/" },
    { name: "GEMPAD", link: "https://gempad.app/presale/all" },
    { name: "HOTKEY", link: "http://www.hotkey.pub/" },
    { name: "SOLIDproof", link: "https://solidproof.io/" },
    { name: "Lodestar", link: "https://lodestargroup.co/" }
];

const Partners = () => {
    return (
        <section className="Partners" id="Partners">
            <div className="second-Partners">
                {partnersData.map((partner, index) => (
                    <div key={index} className="Partner-div">
                        <a href={partner.link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={require(`../../assets/Partners/PartnerLogos/${partner.name}.png`)}
                                className='Partner-image'
                                alt={partner.name}
                            />
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Partners;
